import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

export const PrivacyPolicy = () => (
  <div>
    <Header active="privacy" />
    <main role="main" className="container mt-4">
      <h2>Privacy Policy for MotivaPulse</h2>
      <p>Last Updated: 13/01/2024</p>
      <p>
        Welcome to MotivaPulse! This privacy policy explains how we collect,
        use, and protect your personal information when you use our mobile
        application.
      </p>

      <h3>
        By using MotivaPulse, you agree to the terms outlined in this privacy
        policy.
      </h3>

      <h4>Information We Collect</h4>

      <p>
        <strong>User-Provided Information</strong>
      </p>
      <ul>
        <li>
          <strong>Account Information:</strong> When you create an account, we
          may collect your name, email address, and social media information if
          you choose to log in using those credentials.
        </li>
        <li>
          <strong>User Submissions:</strong> If you submit motivational
          sentences or engage in the in-app community, we may collect the
          content you provide.
        </li>
      </ul>

      <p>
        <strong>Automatically Collected Information</strong>
      </p>
      <ul>
        <li>
          <strong>Device Information:</strong> We collect information about your
          device, including device type, operating system, and unique device
          identifiers.
        </li>
        <li>
          <strong>Usage Information:</strong> We gather information about how
          you use the app, such as the features you interact with and the
          frequency of use.
        </li>
      </ul>

      <h4>How We Use Your Information</h4>
      <ul>
        <li>
          <strong>Personalization:</strong> We use the collected information to
          personalize your MotivaPulse experience, providing you with relevant
          content and features.
        </li>
        <li>
          <strong>Communication:</strong> We may use your email address to send
          you notifications, updates, and important information related to the
          app.
        </li>
      </ul>

      <h4>Information Sharing</h4>
      <ul>
        <li>
          <strong>User Submissions:</strong> Motivational sentences and
          community engagement may be visible to other users.
        </li>
        <li>
          <strong>Service Providers:</strong> We may share your information with
          third-party service providers for app development, analytics, ads
          delivery, and other necessary functions.
        </li>
        <li>
          <strong>Ads:</strong> Non-intrusive ads may be displayed in the app.
          Ad providers may collect information about your interactions with ads.
        </li>
      </ul>

      <h4>Your Choices</h4>
      <ul>
        <li>
          <strong>Account Settings:</strong> You can update your account
          information and preferences in the app settings.
        </li>
        <li>
          <strong>Opt-Out:</strong> You can opt-out of receiving promotional
          emails by following the instructions provided in the emails.
        </li>
      </ul>

      <h4>Security</h4>
      <p>
        We take reasonable measures to protect your personal information, but no
        method of transmission over the internet or electronic storage is 100%
        secure.
      </p>

      <h4>Changes to This Privacy Policy</h4>
      <p>
        We reserve the right to update this privacy policy at any time. We will
        notify you of significant changes through the app or other communication
        channels.
      </p>

      <h4>Contact Us</h4>
      <p>
        If you have questions or concerns about this privacy policy, please
        contact us at info@motivapulse.com.
      </p>

      <p>Thank you for using MotivaPulse!</p>
    </main>
    <Footer />
  </div>
);
