import homeBackgroundImage from "../images/home-backgroung.jpg";

import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

export const Home = () => (
  <div className="home">
    <Header fixed active="home" />
    <div className="fullscreen-container">
      <img
        className="fullscreen-image"
        src={homeBackgroundImage}
        alt="Coming Soon"
      />
      <div className="centered-text text-white">
        <h1>Coming Soon</h1>
      </div>
    </div>
    <Footer fixed />
  </div>
);
