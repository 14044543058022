import { Link } from "react-router-dom";

export const Header = ({ fixed, active, className }) => (
  <nav
    className={`navbar navbar-expand-lg bg-dark ${
      fixed ? "fixed-top" : ""
    }  ${className}`}
    data-bs-theme="dark"
  >
    <div className="container-fluid">
      <span className="navbar-brand mb-0 h1">MotivaPulse</span>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav">
          <Link
            className={`nav-link ${active === "home" ? "active" : ""}`}
            aria-current="page"
            to="/"
          >
            Home
          </Link>
          <Link
            className={`nav-link ${active === "privacy" ? "active" : ""}`}
            aria-current="page"
            to="/privacy-policy"
          >
            Privacy Policy
          </Link>
          <Link
            className={`nav-link ${active === "support" ? "active" : ""}`}
            aria-current="page"
            to="/support"
          >
            Support
          </Link>
        </div>
      </div>
    </div>
  </nav>
);
