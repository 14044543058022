import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

export const Support = () => (
  <div className="support">
    <Header fixed active="support" />
    <main className="fullscreen-container bg-info-subtle">
      <div className="centered-text">
        <h2>Support</h2>
        <p>
          If you need assistance or have any questions, please feel free to
          reach out to our support team.
        </p>
        <p>
          For support inquiries, you can send an email to{" "}
          <a href="mailto:support@motivapulse.com">support@motivapulse.com</a>.
        </p>
      </div>
    </main>
    <Footer fixed />
  </div>
);
