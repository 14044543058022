import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDmLVad5WDhdl_jC6131mWS4kB9s1TuqwQ",
  authDomain: "motivapulse-b1850.firebaseapp.com",
  projectId: "motivapulse-b1850",
  storageBucket: "motivapulse-b1850.appspot.com",
  messagingSenderId: "160188918376",
  appId: "1:160188918376:web:7188057ac7f232d105e9dd",
  measurementId: "G-RSWLTCDWX5",
};

export const initializeFirebase = () => {
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
};
