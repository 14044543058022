export const Footer = ({ fixed, className }) => (
  <nav
    className={`navbar bg-dark ${
      fixed ? "fixed-bottom" : ""
    } text-bg-dark ${className}`}
    data-bs-theme="dark"
  >
    <div className="container-fluid p-2">
      <span>&copy; 2024 Coming Soon Website</span>
    </div>
  </nav>
);
